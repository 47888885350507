<template>
  <div class="container">
    <div class="div1" >大伟嘉股份核心价值观</div>
    <div class="div2">为企业创造持续成长</div>
    <div class="div2">为客户创造价值和服务</div>
    <div class="div2">为员工创造机会和未来</div>
    <div class="div3">为社会创造就业和税收</div>

    <div class="div1">企业训词</div>
    <div class="div3">缔造百年基业  成就人生辉煌</div>

    <div class="div1" >企业宗旨</div>
  <div class="div3">  创伟嘉品牌  圆百年梦想</div>

    <div class="div1"> 发展目标</div>
    <div class="div3">志做全球优秀的养殖及养殖服务商</div>

    <div class="div1">企业理念与精神</div>
    <div class="div2"><span>核心理念</span></div>
    <div class="div2">以用户为中心 以质量为生命 以奋斗者为本</div>
    <div class="div2"><span>经营理念</span></div>
    <div class="div2">笃诚 守信 互利 双赢</div>
    <div class="div2"><span>研发理念</span></div>
    <div class="div2">用户需求是我们创新的动力</div>
    <div class="div2"><span>服务理念</span></div>
    <div class="div2">面向用户 背对公司 把服务送到客户的门口</div>
    <div class="div2"><span>行动理念</span></div>
    <div class="div2">不为眼前利益牺牲长远利益</div>
    <div class="div2"><span>培训理念</span></div>
    <div class="div2">伟嘉不能保证终生聘用你，但会努力使你终生被聘用</div>
    <div class="div2"><span>大伟嘉人精神</span></div>
    <div class="div3">忠诚敬业 开拓进取 永不放弃 追求卓越 共同成长</div>
  </div>
</template>

<script>
  export default {
    name: "Li"
  }
</script>

<style scoped>
  .container{
    width: 100%;
    margin-top: 0.1042vw;
    margin-bottom: 7vw;
    text-align: center;
  }
.div1{
margin-bottom: 1.5417vw;
  font-size: 1.5458vw;
  font-weight: 600;
}
  .div2{
    margin-bottom: 1.2208vw;
    font-size: 1.0333vw;

  }
  .div3{
    margin-bottom: 2.3625vw;
    font-size: 1.0333vw;
  }
  span{
    font-weight: 600;
  }

</style>